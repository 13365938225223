

























import { Component, Vue } from 'vue-property-decorator'
import { vxm } from '@/store/store.vuex'

@Component({})
export default class About extends Vue {
  mounted() {
    this.$store.dispatch('firestore/getAlbums')
  }

  selectAlbum(albumId: string) {
    this.$router.push(`/albums/${albumId}`)
  }

  get albums() {
    return vxm.firestore.albums
  }
}
